import React from 'react';
import { Link } from 'gatsby';
import { Card } from 'react-bootstrap';
import './IntegrationCard.scss';

/** Component which takes in a string which corresponds to a specific card */
function IntegrationCard(props) {
  const { type } = props;

  const card = {
    harvest: {
      title: 'Harvest Weather Stations',
      subtitle: 'Weather Station Hardware',
      colour: '#28A745',
      body: 'Connect Harvest Weather Stations',
      link: 'https://www.hortplus.com/connect-harvest-weather-station-to-hortplus-network/'
    },

    campbell: {
      title: 'Campbell Scientific',
      subtitle: 'Weather Station Hardware',
      colour: '#28A745',
      body: 'Connect Campbell Scientific Weather Stations',
      link: '/technology'
    },

    metris: {
      title: 'Metris Nodes',
      subtitle: 'Weather Station Hardware',
      colour: '#28A745',
      body: 'Metris Weather Nodes',
      link: '/technology'
    },

    ricado: {
      title: 'Ricado',
      subtitle: 'Weather Data Systems',
      colour: '#28A745',
      body:
        'Connect Ricado industrial monitoring systems',
      link: '/ricado'
    },

    property: {
      title: 'Property ID Systems',
      subtitle: 'Properties & Blocks',
      colour: '#83366D',
      body:
        'Integrate industry property and block naming systems such as Apple RPINs',
      link: '/technology'
    },
    
    residues: {
      title: 'Residue Testing Data',
      subtitle: 'Data Integration',
      colour: '#D9382E',
      body: 'Integrate with AsureQuality & Hill Labs data',
      link: '/technology'
    },

    irrigation: {
      title: 'Irrigation Feeds',
      subtitle: 'Irrigation Monitoring',
      colour: '#48A0B5',
      body: 'Integrate irrigation and recommendation services',
      link: '/technology'
    },

    diseasemodels: {
      title: 'Risk Models',
      subtitle: 'Pest & Disease Models',
      colour: '#3F75C6',
      body:
        'Understand potential crop disease risks with a range of models and indicators',
      link: '/disease-models'
    },

    traplog: {
      title: 'HortPlus TrapLog',
      subtitle: 'Pest Monitoring',
      colour: '#691C0A',
      body:
        'Monitor incidence of pests in the field and report on trapping thresholds',
      link: '/technology'
    },

    sprayview: {
      title: 'HortPlus SprayView',
      subtitle: 'Spray Diary Analysis',
      colour: '#4F4F4F',
      body: 'Analyse sprays and residue results at an industry-level',
      link: '/technology'
    },

    sprayplanmanager: {
      title: 'HortPlus SprayPlan',
      subtitle: 'Spray Planning',
      colour: '#4F4F4F',
      body:
        'Determine sensitive areas and hazards for responsible agrichemical use',
      link: '/spray-plan-manager'
    },

    spraylog: {
      title: 'HortPlus SprayLog',
      subtitle: 'Spray Diary Entry',
      colour: '#4F4F4F',
      body: 'Record agrichemical spray applications easily',
      link: '/spraylog'
    },

    dailyreports: {
      title: 'Daily Email Reports',
      subtitle: 'Notifications',
      colour: '#1285D3',
      body: 'Daily email reports customised for grower locations',
      link: '/daily-reports'
    }
  };

  const { title, subtitle, colour, body, link } = card[type];

  const fontStyle = {
    fontWeight: '600',
    fontSize: '24px'
  };

  const colourStyle = {
    backgroundColor: colour,
    padding: '20px',
    borderRadius: '4px 4px 0 0'
  };

  return (
    <Card className='d-block text-center hp-card'>
      <Link to={link} className='hp-card-link'>
        <Card.Subtitle style={colourStyle} className='text-light'>
          {subtitle}
        </Card.Subtitle>
        <Card.Title style={fontStyle} className='hp-card-title text-dark pt-5'>
          <b>{title}</b>
        </Card.Title>
        <Card.Body className='pb-5 pt-3'>{body}</Card.Body>
      </Link>
    </Card>
  );
}

export default IntegrationCard;
